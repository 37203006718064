<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-alert color="red lighten-1" type="error" v-if="alerts" dense>
            {{ alerts }}
          </v-alert>
        </v-row>
      </v-col>

      <v-col cols="12" v-if="!alerts">
        <v-dialog v-model="reserveScheduleDialog" persistent max-width="640px">
          <v-card>
            <v-card-title>确认预约信息</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><strong>教授:</strong> {{ reserveItem.professor.name }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><strong>时间:</strong> {{ reserveItem.desc }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><strong>地点:</strong> {{ reserveItem.location }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><strong>模式:</strong>
                      {{
                        reserveItem.config.one_to_many ? "一对多/OneToMany" : "一对一/OneOnOne"
                      }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn color="primary" @click="confirmJoinSchedule(reserveItem)">确认/Confirm</v-btn>
              <v-btn color="grey lighten-2" @click="closeJoinSchedule()">取消/Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="unReserveScheduleDialog" persistent max-width="640px">
          <v-card>
            <v-card-title>确认取消预约信息</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><strong>教授:</strong> {{ reserveItem.professor.name }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><strong>时间:</strong> {{ reserveItem.desc }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><strong>地点:</strong> {{ reserveItem.location }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      ><strong>模式:</strong>
                      {{
                        reserveItem.config.one_to_many ? "一对多/OneToMany" : "一对一/OneOnOne"
                      }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn color="primary" @click="confirmLeaveSchedule(reserveItem)">确认/Confirm</v-btn>
              <v-btn color="grey lighten-2" @click="closeLeaveSchedule()">取消/Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-card>
          <v-card-title>面谈预约</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="tableHeaders"
              :items="tableContent"
              disable-pagination
              dense
              hide-default-footer
            >
              <template v-slot:top>
                <v-alert color="info" dense text>
                  <h5>提示</h5>
                  <div class="text-caption">{{ deadline }} 后不可修改预约</div>
                  <div class="text-caption">
                    <v-icon small>mdi-account</v-icon> 代表教授一对一面谈，仅能选择教授一个时间段
                  </div>
                  <div class="text-caption">
                    <v-icon small>mdi-account-group</v-icon> 代表教授多人面谈，可选择教授多个时间段
                  </div>
                </v-alert>
              </template>

              <template v-for="professor in professors" v-slot:[`header.${professor.id}`]>
                <v-menu open-on-hover bottom offset-y :key="professor.id">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip label outlined small v-bind="attrs" v-on="on">
                      <template v-if="professor.schedule_config">
                        <v-icon left>{{
                          professor.schedule_config.one_to_many
                            ? "mdi-account-group"
                            : "mdi-account"
                        }}</v-icon>
                      </template>
                      {{ professor.name }}
                    </v-chip>
                  </template>
                  <v-list dense>
                    <v-list-item-group>
                      <v-list-item>
                        <v-list-item-title @click="viewPost(professor)">
                          <v-icon>mdi-post</v-icon>
                          查看海报/View Post
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item link :href="`mailto:${professor.email}`">
                        <v-list-item-title>
                          <v-icon>mdi-email</v-icon>
                          发送邮件/Send Email
                        </v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </template>

              <template v-for="professor in professors" v-slot:[`item.${professor.id}`]="{ item }">
                <v-chip
                  :key="professor.id"
                  v-if="item[professor.id] == null"
                  color="grey"
                  small
                  outlined
                  dark
                >
                  -
                </v-chip>
                <template v-else>
                  <v-menu
                    v-if="attendedSchedules[item[professor.id].id]"
                    :key="professor.id"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" color="green" small dark>
                        已预约/Reserved
                      </v-chip>
                    </template>

                    <v-card dark color="teal lighten-2">
                      <v-card-title>{{ professor.name }} </v-card-title>
                      <v-card-subtitle>
                        {{ item[professor.id].desc }}
                      </v-card-subtitle>
                      <v-card-text
                        v-if="
                          item[professor.id].location.startsWith('https://meeting.tencent.com/')
                        "
                      >
                        <v-card-actions class="justify-center">
                          <v-btn
                            dark
                            color="primary lighten-1"
                            :href="item[professor.id].location"
                            target="_blank"
                          >
                            <v-icon left>mdi-laptop-account</v-icon>
                            进入腾讯会议
                          </v-btn>
                        </v-card-actions>
                      </v-card-text>
                      <v-card-text v-else>地点：{{ item[professor.id].location }}</v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="justify-center">
                        <v-btn
                          dark
                          small
                          color="red lighten-1"
                          @click="leaveSchedule(item[professor.id])"
                        >
                          取消预约/Unreserve
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>

                  <template v-else>
                    <v-chip
                      :key="professor.id"
                      v-if="
                        attendedPresets[item[professor.id].preset.id] ||
                        attendedProfessors[professor.id]
                      "
                      color="warning"
                      small
                      dark
                      outlined
                    >
                      冲突/Conflict
                    </v-chip>
                    <template v-else>
                      <v-chip
                        :key="professor.id"
                        color="info"
                        small
                        outlined
                        dark
                        v-if="item[professor.id].available"
                        @click="joinSchedule(item[professor.id])"
                      >
                        <v-icon small left>mdi-plus</v-icon>
                        参加/Join
                      </v-chip>
                      <v-chip :key="professor.id" v-else color="error" outlined small dark>
                        已约满/Full
                      </v-chip>
                    </template>
                  </template>
                </template>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import requests from "@/http";
import mimeDB from "mime-db";

export default {
  data: () => ({
    alerts: null,
    deadline: null,
    professors: {},
    attendedSchedules: {},
    attendedPresets: {},
    attendedProfessors: {},
    tableHeaders: [],
    tableContent: [],
    reserveItem: {
      desc: "",
      location: "",
      preset: {},
      professor: {},
      config: {},
    },
    reserveScheduleDialog: false,
    unReserveScheduleDialog: false,
  }),
  created() {
    this.getAllSchedules();
  },
  methods: {
    getAllSchedules() {
      requests
        .get(`/api/camp/schedules`)
        .then((res) => {
          const presets = res.data.presets;
          const professors = res.data.professors;
          const attendances = res.data.attendances;
          this.professors = professors;
          this.deadline = res.data.deadline;

          this.attendedSchedules = {};
          this.attendedPresets = {};
          this.attendedProfessors = {};
          attendances.forEach((att) => {
            this.attendedSchedules[att.id] = att;
            this.attendedPresets[att.preset.id] = att;
            if (!att.config.one_to_many) {
              this.attendedProfessors[att.professor.id] = att;
            }
          });

          this.tableHeaders = [
            { text: "时间/Time", align: "start", value: "time", width: "160px" },
          ];
          professors.forEach((pro) => {
            this.tableHeaders.push({
              text: pro,
              align: "center",
              value: String(pro.id),
              sortable: false,
            });
          });

          this.tableContent = [];
          presets.forEach((pre) => {
            var line = { time: pre.desc };
            pre.schedules.forEach((s) => {
              line[String(s.professor.id)] = s;
            });
            this.tableContent.push(line);
          });
        })
        .catch((err) => {
          this.alerts = "数据错误/Data Error";
          console.log(err);
          this.alerts = err.response.data.detail;
        });
    },
    confirmJoinSchedule(item) {
      requests
        .post(`/api/camp/schedule/reserve`, {
          schedule_id: item.id,
        })
        .then(() => {
          this.getAllSchedules();
          alert("预约成功/Successfully Reserved");
          this.closeJoinSchedule();
        })
        .catch((err) => {
          alert(JSON.stringify(err.response.data.detail));
        });
    },
    closeJoinSchedule() {
      this.reserveScheduleDialog = false;
      this.$nextTick(() => {
        this.reserveItem = { preset: {}, professor: {}, config: {} };
      });
    },
    joinSchedule(item) {
      this.reserveItem = item;
      this.reserveScheduleDialog = true;
    },

    closeLeaveSchedule() {
      this.unReserveScheduleDialog = false;
      this.$nextTick(() => {
        this.reserveItem = { preset: {}, professor: {}, config: {} };
        this.getAllSchedules();
      });
    },
    leaveSchedule(item) {
      this.reserveItem = item;
      this.unReserveScheduleDialog = true;
    },
    confirmLeaveSchedule(item) {
      requests
        .post(`/api/camp/schedule/unreserve`, {
          schedule_id: item.id,
        })
        .then(() => {
          this.getAllSchedules();
          alert("取消预约成功/Successfully Unreserved");
          this.closeLeaveSchedule();
        })
        .catch((err) => {
          alert(JSON.stringify(err.response.data.detail));
        });
    },

    forceFileDownload(res, title) {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }));
      const link = document.createElement("a");
      link.href = url;
      console.log(url);
      link.download = title;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    viewPost(item) {
      requests
        .get(`/api/camp/professor/post/${item.id}`, { responseType: "blob" })
        .then((res) => {
          const ext = mimeDB[res.data.type].extensions[0];
          const fname = `夏令营海报-${item.name}.${ext}`;
          this.forceFileDownload(res, fname);
        })
        .catch((err) => {
          if (err.response.status == 404) {
            alert("未上传海报/Post not uploaded");
          }
        });
    },
  },
};
</script>
